<div class="not-found">
  <div class="__inner">
    <img src="{{ logoPath }}" alt="logo" />
    <div class="modal-container">
      <i class="content-icon icon-type-error"></i>
      <h4 [innerHTML]="'header' | lang: LANG_CONFIG_KEY | async"></h4>
      <p [innerHTML]="'header_desc' | lang: LANG_CONFIG_KEY | async"></p>

      <sliqpay-button
        *ngIf="authService.currentUser()"
        [config]="backHomeBtnConfig$ | async"
        (clicked)="goToHome()"
      ></sliqpay-button>
    </div>
  </div>
</div>

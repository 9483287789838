export enum ProviderJourneyActionTypes {
  // plans only
  Sms = 'sms',

  // proposals only
  PrintAndSms = 'print_and_sms',
  PrintAndDownload = 'print_and_download',

  // shared
  OnsiteEntry = 'onsite_entry',
  Edit = 'edit'
}

import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectionStrategy, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, concat, Subject } from 'rxjs';

import { SCREEN_SIZE } from '@app/core/enums/screen-size.enum';
import { AuthService } from '@app/core/services/auth/auth.service';
import { EnvironmentService } from '@app/core/services/environment.service';
import { LogoService } from '@app/core/services/logo/logo.service';
import { UserSettingsService } from '@app/core/services/user-settings/user-settings.service';
import { ModalComponent } from '@core/components/modal/modal.component';
import { ProviderInfo } from '@core/models';
import { StorageService } from '@core/services/storage.service';
import { environment } from '@environments/environment';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sliqpay-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [style({ transform: 'translateX(-100%)' }), animate('.2s', style({ transform: 'translateX(0%)' }))]),
      transition(':leave', [style({ transform: 'translateX(0%)' }), animate('.2s', style({ transform: 'translateX(-100%)' }))])
    ])
  ]
})
export class SidenavComponent implements OnInit, OnDestroy, AfterViewInit {
  static DESKTOP_WIDTH = 1024;
  @Input() windowWidth$ = new BehaviorSubject(SidenavComponent.DESKTOP_WIDTH);

  LANG_CONFIG_KEY = 'side_nav';

  themePath = environment.client;

  mobile = new BehaviorSubject(true);
  user = this.authService.currentUser();
  selectedProviderId = sessionStorage.getItem('provider');
  openMenuId: string | null = sessionStorage.getItem('active-menu') ? sessionStorage.getItem('active-menu') : null;
  practiceList$ = new BehaviorSubject<ProviderInfo[] | null>(null);
  permission$ = new BehaviorSubject(null);
  placement = 'right-bottom';
  logoPath = '';

  private unsubscribe$ = new Subject();
  private RELOAD = 'reload';

  constructor(
    private authService: AuthService,
    private router: Router,
    private modal: NgbModal,
    private storageService: StorageService,
    private userSettingsService: UserSettingsService,
    private environmentService: EnvironmentService,
    private logoService: LogoService
  ) {}

  ngAfterViewInit() {
    this.detectScreenSize();
  }

  ngOnInit(): void {
    this.logoPath = this.logoService.getLogoFilePath('white');

    this.permission$.next(this.user.permissions);
    this.practiceList$.next(JSON.parse(sessionStorage.getItem('providers') || ''));

    this.storageService.sidebarOpened.subscribe((data) => {
      this.mobile.next(data === 'true');
    });
    this.windowWidth$.next(window.innerWidth);

    this.windowWidth$.subscribe((data) => {
      if (data > SidenavComponent.DESKTOP_WIDTH) {
        this.mobile.next(true);
        this.storageService.sidebarState = 'true';
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openMenu(sectionName: string) {
    this.openMenuId = this.openMenuId === sectionName ? null : sectionName;
    sessionStorage.setItem('active-menu', sectionName);
  }

  closeMenu(value: boolean): void {
    if (this.windowWidth$.value < SidenavComponent.DESKTOP_WIDTH) {
      this.storageService.sidebarState = value.toString();
    }
  }

  closeSidebar() {
    this.storageService.sidebarState = 'false';
  }

  logout() {
    const logOutModal = this.modal.open(ModalComponent, { size: 'sm', centered: true });
    logOutModal.componentInstance.data = {
      title: 'Log Out?',
      content: 'Are you sure to log out?',
      iconClass: 'icon-type-logout',
      closeBtn: true,
      buttons: [
        {
          text: 'Log out',
          class: 'btn-red w-170 mr-1',
          value: 'logOut'
        },
        {
          text: 'Cancel',
          class: 'btn-primary w-170 ml-1',
          value: 'false'
        }
      ]
    };
    logOutModal.closed.subscribe((data) => {
      if (data === 'logOut') {
        this.authService.logout().subscribe(() => {
          sessionStorage.clear();
          this.router.navigate(['/login']);
        });
      }
    });
  }

  changeProvider(provider: ProviderInfo): void {
    this.closeMenu(false);

    this.router.navigate(['/home']).then(() => {
      sessionStorage.setItem('provider', provider?.id);

      concat(
        this.authService.setProvider(provider?.id),
        this.authService.fetchFields().pipe(
          map(() => {
            return this.RELOAD;
          })
        )
      )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((data) => {
          if (data === this.RELOAD) {
            location.reload();
          }
        });
    });
  }

  @HostListener('window:resize', [])
  private onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    const width = window.innerWidth;

    if (width <= SCREEN_SIZE.SM) {
      this.placement = 'top';
    } else {
      this.placement = 'right-bottom';
    }
  }
}

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { PaymentService } from '@core/services/payment.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PlanEditPatientIdService } from './plan-edit-patient-id.service';

@Component({
  selector: 'sliqpay-plan-edit-patient-id',
  templateUrl: './plan-edit-patient-id.component.html',
  styleUrls: ['./plan-edit-patient-id.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PlanEditPatientIdService]
})
export class PlanEditPatientIdComponent implements OnInit, OnDestroy {
  LANG_CONFIG_KEY = 'plan_edit_patient_id';

  @Input() public data: any | null = null;
  @Input() public fieldCode: any | null = null;

  form: UntypedFormGroup;

  loading$ = new BehaviorSubject(false);
  unsubscribe$ = new Subject();

  // Config
  saveBtnConfig$!: Observable<AppButton>;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private paymentService: PaymentService,
    private toastrService: ToastrService,
    private planEditPatientIdService: PlanEditPatientIdService
  ) {
    this.form = this.fb.group({
      practice_patient_id_no: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    this.setConfig();
    this.form.patchValue(this.data);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
    const body = {
      ddrId: this.data.ddrId,
      practice_patient_id_no: this.form.get('practice_patient_id_no')?.value,
      fieldCode: this.fieldCode
    };
    this.loading$.next(true);
    this.paymentService
      .putUpdatePaymentPlan(this.data.ddrId, body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        this.loading$.next(false);
        this.toastrService.success(response.message, 'Edit Patient ID');
        this.activeModal.close(body);
      });
  }

  private setConfig(): void {
    this.saveBtnConfig$ = this.planEditPatientIdService.getSaveBtnConfig();
  }
}

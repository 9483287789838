import { Injectable } from '@angular/core';
import { ProviderType } from '@app/core/enums/provider-type.enum';
import { environment } from '@environments/environment';
import { EnvironmentService } from '../environment.service';
import { UserSettingsService } from '../user-settings/user-settings.service';

@Injectable({
  providedIn: 'root'
})
export class LogoService {
  private readonly IMAGES_PATH = './assets/images';

  themePath = environment.client;

  constructor(private environmentService: EnvironmentService, private userSettingsService: UserSettingsService) {}

  getLogoFilePath(variant: 'big' | 'white' | 'colored', isAfterAuthentication = true): string {
    if (!isAfterAuthentication && this.environmentService.isDentiCare()) {
      return `${this.IMAGES_PATH}/${this.themePath}/orthoengage-${variant}.svg`;
    }

    if (
      this.environmentService.isDentiCare() &&
      this.userSettingsService.getProviderType() === ProviderType.ORTHO &&
      this.userSettingsService.isTreatmentProposalEnabled()
    ) {
      return `${this.IMAGES_PATH}/${this.themePath}/orthoengage-${variant}.svg`;
    }

    return `${this.IMAGES_PATH}/${this.themePath}/logo-${variant}.svg`;
  }
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LogoService } from '@app/core/services/logo/logo.service';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { FullscreenLoaderService, FullscreenLoaderState } from './fullscreen-loader.service';

@Component({
  selector: 'sliqpay-fullscreen-loader',
  templateUrl: './fullscreen-loader.component.html',
  styleUrls: ['./fullscreen-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullscreenLoaderComponent implements OnInit {
  themePath = environment.client;
  logoPath = '';

  fullscreenLoaderState$!: Observable<FullscreenLoaderState>;

  constructor(private service: FullscreenLoaderService, private logoService: LogoService) {}

  ngOnInit(): void {
    this.fullscreenLoaderState$ = this.service.fullscreenUiState$();
    this.logoPath = this.logoService.getLogoFilePath('white');
  }
}

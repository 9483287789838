import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { SettingDataService } from '@app/core/data-services/settings/settings-data.service';
import { Tile } from '@app/core/models';
import { HelperService } from '@app/core/services/helper.service';
import { NotificationService } from '@app/core/services/notification.service';
import { SettingsService } from '@app/settings/services/settings.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppInput } from '@app/shared/interfaces/input.interface';
import * as DDRValidator from '@core/services/ddr.validator';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { SettingsChangePasswordComponent } from '../../components/setting-change-password/settings-change-password.component';
import { SettingsAccountService } from './settings-account.service';

@Component({
  selector: 'sliqpay-settings-account',
  templateUrl: './settings-account.component.html',
  styleUrls: ['./settings-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SettingsAccountService]
})
export class SettingsAccountComponent implements OnInit, OnDestroy {
  LANG_CONFIG_KEY = 'settings_account';
  MAX_FILE_SIZE = DDRValidator.MAX_FILE_SIZE;

  form: UntypedFormGroup;
  loading$ = new BehaviorSubject(false);
  fileName$ = new BehaviorSubject('');

  unsubscribe$ = new Subject();

  // Config
  saveChangesBtnConfig$!: Observable<AppButton>;
  providerNameConfig$!: Observable<AppInput>;
  practiceManagerConfig$!: Observable<AppInput>;
  regDateConfig$!: Observable<AppInput>;
  phoneNumberConfig$!: Observable<AppInput>;
  emailConfig$!: Observable<AppInput>;
  abnConfig$!: Observable<AppInput>;
  websiteConfig$!: Observable<AppInput>;
  clinicTypeConfig$!: Observable<AppInput>;
  managerPhoneConfig$!: Observable<AppInput>;

  glCols = 5;
  glRowHeight = '50px'; // 50px big 100px for mobile
  glGutterSize = '-1px'; // remove gap
  feeScheduleTiles: Tile[] = [];
  disclaimers: string[] = [];

  inputFilePlaceholder = 'Choose file (.pdf, .doc, .docx)';

  constructor(
    public activeModal: NgbActiveModal,
    private helperService: HelperService,
    private fb: UntypedFormBuilder,
    private settingsDataService: SettingDataService,
    private modal: NgbModal,
    private saService: SettingsAccountService,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    protected activatedRoute: ActivatedRoute
  ) {
    this.form = this.fb.group({
      name: [{ value: null }],
      phone: [{ value: '' }],
      email: [{ value: null }],
      contact: [{ value: null }],
      registration_date: [{ value: null }],
      provider_entity_id: [{ value: null }],
      payment_preference: [{ value: null }],
      custentity_weekly_payment_bnpl: [{ value: null }],
      abn: [{ value: null }],
      url: [{ value: '' }, [Validators.pattern(DDRValidator.DDRRegex.getUrlValidation())]],
      clinic_type: [{ value: null }],
      file: [{ value: null }, [DDRValidator.fileSizeValidator]],
      accountmanager_fname: [{ value: null }],
      accountmanager_phone: [{ value: null }],
      accountmanager_email: [{ value: null }]
    });
  }

  ngOnInit(): void {
    this.setConfig();
    this.getSetting();

    this.settingsService.saveAccountSettingsClickListener$().subscribe(() => this.saveChanges());
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  changePassword(): void {
    this.modal.open(SettingsChangePasswordComponent, { centered: true });
  }

  getSetting() {
    this.settingsDataService
      .getSetting()
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ data }) => {
          const fileName = data?.trustee_file_name ? data?.trustee_file_name : 'No uploaded file';
          this.fileName$.next(fileName);
          this.form.patchValue(data);
          this.form.get('provider_entity_id')?.setValue(this.helperService.getObjUser()['provider_entity_id']);

          this.form.get('accountmanager_fname')?.setValue(data?.customer_accountmanager?.entityid);
          this.form.get('abn')?.setValue(data?.vatregnumber);
          this.form.get('accountmanager_phone')?.setValue(data?.customer_accountmanager?.phone);
          this.form.get('accountmanager_email')?.setValue(data?.customer_accountmanager?.email);

          this.glCols = data?.feeSchedule?.glCols;
          this.feeScheduleTiles = data?.feeSchedule?.tile;
          this.disclaimers = data?.feeSchedule?.disclaimer;
        })
      )
      .subscribe();
  }

  handleFileInput(event: any): void {
    if (event?.target?.files?.length > 0) {
      const file = event.target.files[0];
      this.setInputFilePlaceholder(file?.name);
      this.form.get('file')?.setValue(file);
    }
  }

  saveChanges(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.saService
      .updateProviderSettings(
        this.form.get('phone')?.value,
        this.form.get('url')?.value,
        this.form.get('file')?.value ? this.form.get('file')?.value : null
      )
      .pipe(
        map((response) => {
          this.setInputFilePlaceholder();
          this.getSetting();
          this.notificationService.success('Updated Provider Successfully', 'Account Has Been Updated');
        }),
        takeUntil(this.unsubscribe$),
        catchError((err) => of('error', err))
      )
      .subscribe();
  }

  private setConfig(): void {
    this.saveChangesBtnConfig$ = this.saService.getSaveChangesBtnConfig();
    this.providerNameConfig$ = this.saService.getProviderNameConfig();
    this.practiceManagerConfig$ = this.saService.getPracticeManagerConfig();
    this.regDateConfig$ = this.saService.getRegDateConfig();
    this.phoneNumberConfig$ = this.saService.getPhoneNumberConfig();
    this.emailConfig$ = this.saService.getEmailConfig();
    this.abnConfig$ = this.saService.getAbnConfig();
    this.websiteConfig$ = this.saService.getWebsiteConfig();
    this.clinicTypeConfig$ = this.saService.getClinicTypeConfig();
    this.managerPhoneConfig$ = this.saService.getManagerPhoneConfig();
  }

  private setInputFilePlaceholder(fileName: string | null = null): void {
    const file = document.getElementById('customFile')?.nextElementSibling;
    if (file?.innerHTML) {
      file.innerHTML = fileName ? fileName : this.inputFilePlaceholder;
    }
  }
}

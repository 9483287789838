import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/services/config/config.service';
import { HelperService } from '@app/core/services/helper.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppDatePicker } from '@app/shared/interfaces/date-picker.interface';
import { AppDropdown } from '@app/shared/interfaces/dropdown.interface';
import { AppInput } from '@app/shared/interfaces/input.interface';
import { Observable } from 'rxjs';

@Injectable()
export class SupportRequestFormService {
  private CONFIG_KEY = 'support_request_form';

  private objFormFields = this.helperService.getObjFormFields();

  constructor(private configService: ConfigService, private helperService: HelperService) {}

  getNewProvideConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'new_provider');
  }

  getPracticeNameConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'practice_name');
  }

  getPracticeLocationConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'practice_location');
  }

  getEmployeeFullNameConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'employee_fullname');
  }

  getEmployeeRoleConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'employee_role');
  }

  getEmployeeEmailConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'employee_email');
  }

  getEmployeeAssignRoleConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'employee_assign_role');
  }

  getRequestorConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'requestor');
  }

  getSubjectConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'subject');
  }

  getEmailConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'email');
  }

  getSubmitBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'submit_request_btn');
  }

  getRequestTypeDdConfig(): Observable<AppDropdown> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'request_type_dd');
  }

  getRpNameDdConfig(): Observable<AppDropdown> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'rp_name_dd');
  }

  getRelatedPlanDdConfig(): Observable<AppDropdown> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'related_plan_dd');
  }

  getDebitResumeDateDatepickerConfig$(): Observable<AppDatePicker> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'debit_resume_date');
  }

  getPriorityDdConfig(): Observable<AppDropdown> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'priority_dd');
  }

  getObjFormFieldByValue(value: string): any {
    return this.objFormFields[value];
  }
}

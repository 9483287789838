import { Injectable } from '@angular/core';
import { HelperService } from '@core/services/helper.service';
import { BehaviorSubject } from 'rxjs';
import { PaymentFrequency } from '../enums/payment-frequency.enum';
import { PlanType } from '../enums/plan-type.enum';
import { AmendmentQuote } from '../models';

@Injectable({
  providedIn: 'root'
})
export class PlanCalculationService {
  objUser = this.helperService.getObjUser() || null;
  treatmentTypeOrtho = this.objUser?.validation_values.DDR_TREATMENT.ORTHO;
  minDeposit = 0;
  minTermMonths = 0;
  minTreatmentCost = 0;
  maxTreatmentCost = 0;
  minAdditionalPlanAmount = 1;
  minDepositAmount = 0;
  minDepositInAmtBNPL = 0;
  maxTreatmentCostBNPL = 0;
  computeMinTermMonths = 1;
  paymentPlanAmount2k = 2000;
  defaultWeeklyNoOfPayments = 12;
  calcMinTreatmentCostBNPL = 1;
  maxTermMonthsConnect = 36;
  public radioComplianceNoLbl = 'complianceNo';

  calculatorOutput = {
    directDebit: {
      cost: 0.0,
      per: ''
    },
    periodOf: {
      months: 0,
      weeks: 0
    },
    numberOfDebits: 0,
    firstPaymentOn: new Date(),
    finalPaymentOn: new Date()
  };
  maxRepaymentData = {
    valid: true,
    maxRepaymentAmountByFreq: 0
  };
  treatmentTypeDental = this.objUser?.validation_values.DDR_TREATMENT.DENTAL;
  nonGuaranteePlan = this.objUser?.validation_values.DDR_PLAN_TYPE.NON_GUARANTEED;
  today = new Date();
  tomorrow = this.helperService.addDaysToDate(1);
  maxFortnightlyNoOfPayments = 5;
  hideFornightly = false;

  maxDepositObs$ = new BehaviorSubject(0);

  constructor(private helperService: HelperService) {}

  public generateAmendmentQuotes(planAmount: number, directDebitAmount: number, currentQuote: AmendmentQuote[]): Array<AmendmentQuote> {
    return currentQuote.reduce<AmendmentQuote[]>((acc, _quote) => {
      const weeklyTotalDebits = this.calculateNumberOfDebits(PaymentFrequency.Weekly, _quote.term_months, planAmount, directDebitAmount);
      const weeklyCost = this.calculatePerUnitCost(weeklyTotalDebits, planAmount);

      const fortnightlyTotalDebits = this.calculateNumberOfDebits(
        PaymentFrequency.Fortnightly,
        _quote.term_months,
        planAmount,
        directDebitAmount
      );
      const fortnightlyCost = this.calculatePerUnitCost(fortnightlyTotalDebits, planAmount);

      const monthlyTotalDebits = this.calculateNumberOfDebits(PaymentFrequency.Monthly, _quote.term_months, planAmount, directDebitAmount);
      const monthlyCost = this.calculatePerUnitCost(monthlyTotalDebits, planAmount);

      const newQuote: AmendmentQuote = {
        ..._quote,
        weekly: weeklyCost,
        fortnightly: fortnightlyCost,
        monthly: monthlyCost
      };

      return [...acc, newQuote];
    }, []);
  }

  public calculatePerUnitCost(numberOfDebits: number, amountRequiredToPay: number): number {
    // calculate the amount per unit
    let amountPerUnit = amountRequiredToPay / numberOfDebits;

    amountPerUnit = amountPerUnit ? amountPerUnit : 0;

    // https://stackoverflow.com/questions/10413573/rounding-up-to-the-nearest-0-05-in-javascript
    const ceil = Math.ceil(amountPerUnit * 20);
    // console.log('ceil ' + ceil);

    amountPerUnit = ceil / 20;

    // return parseFloat(amountPerUnit.toFixed(2));

    return numberOfDebits !== 0 ? Math.round((amountPerUnit ? amountPerUnit : 0) * 100) / 100 : 0;
  }

  public calculateNumberOfDebits(frequency: string | number, months: number, planAmount: number, debitAmount: number): number {
    let numberOfDebits = 0;
    const weeks = 52;

    if (months === 0) {
      if (planAmount && debitAmount) {
        while (planAmount > 0) {
          numberOfDebits++;
          planAmount -= debitAmount;
        }
      }
      return numberOfDebits;
    } else {
      switch (parseInt(frequency as string, 10)) {
        // weekly payments
        case 1:
          // numberOfDebits = months * 4.428 + weeks;
          numberOfDebits = (months / 12) * weeks;
          break;
        // fortnightly payments
        case 2:
          // numberOfDebits = (months * 4.428 + weeks) / 2;
          numberOfDebits = (months / 12) * (weeks / 2);
          break;
        // monthly payments
        case 3:
          // numberOfDebits = months + weeks / 4.428;
          numberOfDebits = months;
          break;
      }
    }

    // return Math.ceil(numberOfDebits);
    return Math.floor(numberOfDebits);
  }

  public setDDRSinglePayments(frequency: any, frequencies: any[]): string | null {
    if (frequency) {
      return this.helperService.strtolower(
        this.helperService.getObjectByValue(frequencies, 'internalid', frequency)
          ? this.helperService.getObjectByValue(frequencies, 'internalid', frequency).name
          : ''
      );
    }
    return null;
  }

  // NEW
  // TO DO:  Delete all old codes for plan calculation after integrating the new functions in support amendment and template
  isDentalProviderAndOrthoTreatment(treatmentType: string, planType: string): boolean {
    this.objUser = this.helperService.getObjUser();
    return this.objUser?.clinic_type === '1' && treatmentType === this.treatmentTypeOrtho.toString() && planType !== PlanType.Bnpl;
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import { AuthService } from '@app/core/services/auth/auth.service';
import { LogoService } from '@app/core/services/logo/logo.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppInput } from '@app/shared/interfaces/input.interface';
import { ModalComponent } from '@core/components/modal/modal.component';
import { ForgotPasswordRequest } from '@core/models';
import { environment } from '@environments/environment';
import { ForgotPasswordPageService } from './forgot-password-page.service';

@Component({
  selector: 'sliqpay-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss', '../login-page/login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordPageComponent implements OnInit, OnDestroy {
  LANG_CONFIG_KEY = 'forgot_password';

  form: UntypedFormGroup;
  themePath = environment.client;
  logoPath = '';

  loading$ = new BehaviorSubject(false);
  unsubscribe$ = new Subject<boolean>();

  // Field config
  emailConfig$!: Observable<AppInput>;
  backBtnConfig$!: Observable<AppButton>;
  sendBtnConfig$!: Observable<AppButton>;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private modal: NgbModal,
    private router: Router,
    private fpPageService: ForgotPasswordPageService,
    private logoService: LogoService
  ) {
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
    this.logoPath = this.logoService.getLogoFilePath('big', false);

    this.setConfig();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  forgotPassword() {
    if (!this.form.valid) {
      return;
    }
    const request: ForgotPasswordRequest = {
      email: this.form.value.email,
      email_confirmation: this.form.value.email
    };
    this.loading$.next(true);

    this.authService
      .forgotPassword(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.loading$.next(false);
          return throwError(error);
        })
      )
      .subscribe(() => {
        this.loading$.next(false);
        const modal = this.modal.open(ModalComponent, { size: 'sm', centered: true });
        modal.componentInstance.data = {
          title: 'Sent',
          content: 'We have just emailed you a temporary password. Please check your inbox',
          iconImage: 'icon-green-check',
          closeBtn: true,
          buttons: [
            {
              text: 'Back to log in',
              class: 'btn-primary',
              value: 'login'
            }
          ]
        };
        modal.closed.pipe(takeUntil(this.unsubscribe$)).subscribe((response) => {
          if (response === 'login') {
            this.router.navigate(['login']);
          }
        });
      });
  }

  goToLoginPage(): void {
    this.router.navigate(['/login']);
  }

  private setConfig(): void {
    this.emailConfig$ = this.fpPageService.getEmailConfig();
    this.backBtnConfig$ = this.fpPageService.getBackBtnConfig();
    this.sendBtnConfig$ = this.fpPageService.getSendBtnConfig();
  }
}

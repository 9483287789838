import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';

import { EnvCompanyBrand } from '@app/core/enums/brand.enum';
import { AuthService } from '@app/core/services/auth/auth.service';
import { LogoService } from '@app/core/services/logo/logo.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppInput } from '@app/shared/interfaces/input.interface';
import { CreateNewPasswordComponent } from '@auth/components/create-new-password/create-new-password.component';
import { environment } from '@environments/environment';
import { finalize, switchMap } from 'rxjs/operators';
import { LoginService } from './login-page.service';

@Component({
  selector: 'sliqpay-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginPageComponent implements OnInit, OnDestroy {
  LANG_CONFIG_KEY = 'login_page';

  logoPath = '';

  themePath = environment.client;
  form: UntypedFormGroup = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    password: [null, Validators.required]
  });

  loading$ = new BehaviorSubject(false);

  // Config
  emailConfig$!: Observable<AppInput>;
  passwordConfig$!: Observable<AppInput>;
  loginBtnConfig$!: Observable<AppButton>;

  private GO_TO_HOME = 'goToHome';
  private GO_TO_PROVIDER = 'goToProvider';

  private subs = new Subscription();

  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private router: Router,
    public modalService: NgbModal,
    private loginService: LoginService,
    private logoService: LogoService
  ) {}

  ngOnInit(): void {
    this.logoPath = this.logoService.getLogoFilePath('big', false);

    if (!this.authService.currentUser()) {
      this.authService
        .setRulesJson(this.router)
        .then(() => {
          this.setLastCache();
        })
        .catch(() => this.loading$.next(false));
    }
    this.setConfig();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  login(): void {
    if (!this.form.valid) {
      return;
    }

    this.loading$.next(true);
    this.subs.add(
      this.authService
        .login(this.form.value)
        .pipe(
          switchMap((user: any) => {
            if (user.custentity_pv_requirepwdchange) {
              return this.openCreateNewPword();
            }

            if (sessionStorage.getItem('providers') && JSON.parse(sessionStorage.getItem('providers') || '').length === 1) {
              const provider = JSON.parse(sessionStorage.getItem('providers') || '')[0];
              sessionStorage.setItem('provider', provider?.id);
              return this.authService.setProvider(provider?.id).pipe(
                switchMap(() => {
                  return this.authService.fetchFields().pipe(
                    switchMap(() => {
                      return of(this.GO_TO_HOME);
                    })
                  );
                })
              );
            }

            return of(this.GO_TO_PROVIDER);
          }),
          finalize(() => this.loading$.next(false))
        )
        .subscribe((result: any) => {
          if (result === this.GO_TO_HOME) {
            this.router.navigate(['/home']);
          }

          if (result === this.GO_TO_PROVIDER) {
            this.router.navigate(['/login/select-provider']);
          }
        })
    );
  }

  refreshPage(): void {
    window.location.reload();
    sessionStorage.setItem('last_clear_cache_datetime', Date.now().toString());
  }

  private setLastCache(): void {
    if (sessionStorage.getItem('last_clear_cache_datetime')) {
      if (
        parseInt(sessionStorage.getItem('last_clear_cache_datetime') || '', 10) <= this.authService.getProductRules('clear_cache_datetime')
      ) {
        this.refreshPage();
      }
    } else {
      if (this.authService.getProductRules('clear_cache_datetime') <= Date.now()) {
        this.refreshPage();
      }
    }
  }

  private openCreateNewPword() {
    const modalRef = this.modalService.open(CreateNewPasswordComponent, { size: 'sm' });
    return modalRef.closed;
  }

  private setConfig(): void {
    this.emailConfig$ = this.loginService.getEmailConfig();
    this.passwordConfig$ = this.loginService.getPasswordConfig();
    this.loginBtnConfig$ = this.loginService.getLoginBtnConfig();
  }
}
